.order-status-button {
  color: #fff !important;
  box-shadow: none !important;
}

.pending {
  background-color: #ef6c9c !important;
}
.processing,
.order-status-button.active {
  background-color: #5b841b !important;
}
.cancelled,
.order-status-button.dead {
  background-color: #eb0303 !important;
}
.payment-pending {
  background-color: #5f77eb !important;
}
.on-hold,
.order-status-button.cold {
  background-color: #e1960e !important;
}
.mockup-sent,
.order-status-button.hot {
  background-color: #39bf49 !important;
}
.artwrok-missing {
  background-color: #9b5b4d !important;
}
.mockup-approved {
  background-color: #028faf !important;
}
.sendtoprint {
  background-color: #2a2929 !important;
}
.refunded,
.refund {
  background-color: #7a0312 !important;
}
.onhold-checknotes {
  background-color: #151f9afa !important;
}
.job-printed,
.order-status-button.converted {
  background-color: #096ed7 !important;
}
.job-printeds {
  background-color: #096ed7 !important;
}
.changes-required {
  background-color: #b1018f !important;
}
.order-status-button.honey_sehgal {
  background-color: #b1018f !important;
}
.order-status-button.priya {
  background-color: #096ed7 !important;
}
.order-status-button.jeetu {
  background-color: #151f9afa !important;
}
.order-status-button.kristina {
  background-color: #2a2929 !important;
}
.order-status-button.ankit_srivastava {
  background-color: #e1960e !important;
}
.order-status-button.baval {
  background-color: #9b5b4d !important;
}
.order-status-button.vijay {
  background-color: #134f5c !important;
}
