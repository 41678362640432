body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 991px) {
  html {
    overflow-x: hidden;
  }
  body {
    overflow-x: hidden;
  }
}
@media (max-width: 599.95px) {
  .MuiDialog-container .MuiDialogTitle-root {
    padding: 16px;
  }
  .MuiDialogTitle-root .MuiIconButton-root {
    padding: 0;
  }
}
/* body .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .MuiTablePagination-selectRoot {
  display: none !important;
} */
.markpaid {
  background: red !important;
}
.orderpaid {
  background: green !important;
}
/* //Global Style */
.heading {
  font-size: 23px;
  line-height: 1.3;
  margin-right: 1rem;
}
.table.custom-table tr td {
  padding: 10px 24px 10px 16px;
}
.show-data-modal-heading {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.meta-data {
  align-items: center;
  justify-content: space-between;
  gap: 22px;
  margin: 10px;
  width: 100%;
}

.meta-data-head {
  font-size: 14px I !important;
  font-weight: 600 !important;
}

.meta-data-value {
  font-size: 14px !important;
  font-weight: 500 !important;
}
button.agree-btn {
  background: #0a74ea;
}

button.reject-btn {
  background: #eb0303;
  margin-left: 10px;
}
.custom-table thead th {
  font-size: 14px;
  font-weight: 700;
  background: #f1eded;
  color: #000;
}
button.approved-btn {
  background: #5b841b;
}
button.rejected-btn {
  background: #9b0101;
}
span.highlight-yes {
  color: green;
  font-size: 16px;
  font-weight: 700;
}
